import React from "react"

import Layout from "../../components/Layout"
import lab1 from "../../images/bannerpic1_r.jpg"
import lab2 from "../../images/engajadoslab.jpg"
import { Link } from "gatsby"


export default function Home() {
  return (
    <Layout breadcrumbs={["EngajadosLab"]}>
      <div id="heading1" >
        <h1>EngajadosLab</h1>
      </div>

      {/* Main */}
      <section id="main" className="wrapper">
        <div className="inner">

          <div className="content page">

            {<h5><Link to="/">Inicio</Link> &gt;&gt; Projetos  &gt;&gt; EngajadosLab</h5>}

            <header>
              <h2>P&D multidisciplinar que pensa o papel da tecnologia</h2>
            </header>

            <div style={{ display: 'flex' }}>
              <div>
                <p className="headline">Pensamos, aprimoramos, e desenvolvemos nossas tecnologias com apoio de um grupo de pesquisa multidisciplinar. </p>
                <p><span className="image right desktop-only"><img src={lab1} alt="Imagem de pessoas juntando as mãos" /></span>
                O EngajadosLab é o nosso grupo de Pesquisa e Desenvolvimento (P&D). Composto de membros internos e externos, 
                a principal característica do Lab é a multidisciplinaridade dos seus integrantes, que se encontram periodicamente 
                para pensar e aprimorar tecnologias para a colaboração e o protagonismo de impacto positivo, por nós desenvolvidas. 
                </p>
              <span className="image-page mobile-only"><img src={lab1} /></span>                
                <p>Nosso lab surgiu de maneira orgânica, a partir do diferencial da nossa proposta de atuação, e se consolidou 
                  em torno de algumas constatações:
                <br></br>(a) Tecnologias digitais devem ser pensadas multidisciplinarmente, sempre que o objetivo for facilitar a 
                emergência da inteligência e da sensibilidade humana colaborativa;
                <br></br>(b) Boas soluções digitais requerem bons conceitos, mas também boas práticas cooperativas entre especialistas e 
                a comunidade que as requer;
                <br></br>(c) Tecnologias são reaproveitáveis, porém demandam constante reflexão, aprimoramento, readequação e desenvolvimento. 
                </p>
                <p><span className="image left desktop-only"><img src={lab2} alt="" /></span>
                No EngajadosLab, portanto, tratamos dos aspectos conceituais e práticos da colaboração e do protagonismo mediado por tecnologias digitais, 
                sempre estimulados pelas demandas específicas de nossas iniciativas e parcerias.</p>
              <span className="image-page mobile-only"><img src={lab2} /></span>
                <p>O Lab coloca-se, assim, como um espaço que viabiliza a visão mais ampla do Instituto Engajados, ao mesmo tempo 
                  em que contribui para os projetos em andamento e pauta o desenvolvimento do nosso framework.</p>
                {/* <ul>
                  <li>Acesso ao projeto (versão beta) em: <a href="http://monitoraea.org.br/" target="_blank">www.monitoraea.org.br</a></li>
                </ul> */}
              </div>
              {/* <div>
                <span className="image special"><img src={lab1} alt="" /></span>
                <span className="image special"><img src={lab2} alt="" /></span>
                <span className="image special"><img src={lab1} alt="" /></span>
              </div> */}
            </div>

          </div>
        </div>
      </section>
    </Layout>
  )
}
